import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MoralisProvider } from "react-moralis";
import "./index.css";
import QuickStart from "components/QuickStart";
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
import { ThemeProvider } from 'styled-components'
import { light, dark } from '@thaihuuluong/uikit'
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'

/** Get your free Moralis Account https://moralis.io/ */

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

function getLibrary(provider) {
  return new Web3(provider)
}

const Application = () => {
  const isServerInfo = APP_ID && SERVER_URL ? true : false;
  if (isServerInfo)
    return (
      <Web3ReactProvider getLibrary={getLibrary}>
          <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
            <ThemeProvider theme={light}>
                <MoralisDappProvider>
                      <App isServerInfo />
                </MoralisDappProvider>
            </ThemeProvider>
          </MoralisProvider>
      </Web3ReactProvider>
    
    );
  else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <QuickStart />
      </div>
    );
  }
};

ReactDOM.render(
  // <React.StrictMode>
  <Application />,
  // </React.StrictMode>,
  document.getElementById("root")
);
