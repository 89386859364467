import { Flex, Text } from '@thaihuuluong/uikit';
import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'components/Select';
import contract from "components/contract"
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider';
import Account from './Account';
import Chains from './Chains';
import NumberFormat from 'react-number-format';
import { useSwapNft } from '../hooks/useSwapNft'


const SwapNft = () => {

    const { chainId, walletAddress } = useMoralisDapp();
    
    const [receviedInput, setRceviedInput] = useState(0)
    const [receviedOutput, setReceviedOutput] = useState('')
    const [state, setState] = useState('')
    const contractAddress = contract.fundgoNFT[chainId]


    const [ typeNftIn, setTypeNftIn ] = useState(10000)
    const [ typeNftOut, setTypeNftOut ] = useState(10000)

    const handleSortOptionChangeIn = (options) => {
        setTypeNftIn(options.value)
    }
    const handleSortOptionChangeOut = (options) => {
        setTypeNftOut(options.value)
    }

    const { handleSwap, requested, penddingSwap } = useSwapNft(typeNftIn, receviedInput, typeNftOut, chainId );

    return (
        <Container>
             <Header>
                <HeaderFlex>
                    <Chains/>
                    <Account/>
                </HeaderFlex>
            </Header>
            <Wraper>
                <TableTransfer>
                    <IconSwap src='/images/Transfer/swap.png'/>
                    <SwapTransfer>
                        <Flex width='100%' justifyContent='center'>
                            <TextNFT>Swap NFTs</TextNFT>
                        </Flex>
                        <CustomFlex height='100%' width='100%' flexDirection='column' justifyContent='space-around'>
                            <TableSwapUp>
                                <TableContents>
                                    <TableSelect>
                                        <Select
                                        options={[
                                        {
                                            label: 'NFT 10,000 VNĐ',
                                            value: 10000,
                                        },
                                        {
                                            label: 'NFT 100,000 VNĐ',
                                            value: 100000,
                                        },
                                        {
                                            label: 'NFT 1,000,000 VNĐ',
                                            value: 1000000,
                                        },
                                        {
                                            label: 'NFT 10,000,000 VNĐ',
                                            value: 10000000,
                                        },
                                        {
                                            label: 'NFT 100,000,000 VNĐ',
                                            value: 100000000,
                                        },
                                        {
                                            label: 'NFT 1,000,000,000 VNĐ',
                                            value: 1000000000,
                                        }
                                        ]}
                                        onChange={handleSortOptionChangeIn}
                                    />
                                    </TableSelect>
                                    <TableInput>
                                        <CustomNumberFormat
                                        placeholder='0.0'
                                        value={receviedInput}
                                        thousandSeparator={!false}
                                        onValueChange={(values) => {
                                        const { value } = values;
                                        setRceviedInput(parseFloat(value))
                                            }}
                                        />
                                    </TableInput>
                                </TableContents>
                            </TableSwapUp>
                            <TableSwapDown>
                                <TableContents>
                                    <TableSelect>
                                        <Select
                                            options={[
                                            {
                                                label: 'NFT 10,000 VNĐ',
                                                value: 10000,
                                            },
                                            {
                                                label: 'NFT 100,000 VNĐ',
                                                value: 100000,
                                            },
                                            {
                                                label: 'NFT 1,000,000 VNĐ',
                                                value: 1000000,
                                            },
                                            {
                                                label: 'NFT 10,000,000 VNĐ',
                                                value: 10000000,
                                            },
                                            {
                                                label: 'NFT 100,000,000 VNĐ',
                                                value: 100000000,
                                            },
                                            {
                                                label: 'NFT 1,000,000,000 VNĐ',
                                                value: 1000000000,
                                            }
                                            ]}
                                            onChange={handleSortOptionChangeOut}
                                        />
                                    </TableSelect>
                                    <TableInput>
                                        <CustomNumberFormat
                                            placeholder='0.0'
                                            value={receviedOutput}
                                            />
                                    </TableInput>
                                    </TableContents>
                            </TableSwapDown>
                        </CustomFlex>
                        <Flex width='100%' justifyContent='center'>
                            <ButtonSwap onClick={handleSwap}>Trao đổi</ButtonSwap>
                        </Flex>
                    </SwapTransfer>
                </TableTransfer>
            </Wraper>
        </Container>
    );
};

export default SwapNft;

const Header = styled.div`
    display:flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    justify-content:flex-end;
    width:100%;
    @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: center;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
 

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {

    }
`
const HeaderFlex = styled(Flex)`
    gap: 20px;
    @media screen and (max-width: 600px) {
        width: 90%;
        justify-content: space-around;
        gap: 5px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 90%;
        justify-content: space-around;
        gap: 5px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {


    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {

    }
`
const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top:2rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        background: none;
        padding: 10px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: auto;
        flex-direction: column;
        background: none;
        padding: 10px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
        height: 100%;
    }
`
const Wraper = styled.div`
    width: 100%;
    height: auto;
    padding: 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0rem;
        margin-top: 10rem;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
    }
`
const TableTransfer = styled.div`
    width: 810px;
    height: 600px;
    background: url('/images/Transfer/BgTransfer.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 100%;
        background: none;
        height: 20vh;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
    }
`
const SwapTransfer = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
        width: 90%;
        height: 90%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 90%;
        height: 90%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 90%;
        height: 90%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 90%;
        height: 90%;
    }
`
const IconSwap = styled.img`
    position: absolute;
    @media screen and (max-width: 600px) {
        width: 50px;
        height: 50px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 70px;
        height: 70px;
        margin-top: 18px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 70px;
        height: 70px;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 70px;
        height: 70px;
    }
`
const TableSwapUp = styled.div`
    width: 100%;
    height: 45%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 10%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
    }
`
const TableSwapDown = styled.div`
    width: 100%;
    height: 45%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 10%;

    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: 40%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
    }
`
const TableContents = styled.div`
    width: 90%;
    height: 130px;
    background: rgba(231, 204, 155, 0.5);
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: 10vh;

    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 90%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 90%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 90%;
    }
`
const TableSelect = styled.div`
    width: 38%;
    height: 60px;
    background: #E7CC9BCC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
        width: 65%;
        height: 50%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 40%;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 40%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 40%;
    }
`
const TableInput = styled.div`
    width: 52%;
    height: 60px;
    background: #E7CC9BCC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
        width: 30%;
        height: 50%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 50%;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 50%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 50%;
    }
`
const CustomNumberFormat = styled(NumberFormat)`
    width: auto;
    border: none;
    background: none;
    text-align: right;
    font-weight: bold;
    font-size: 24px;
    margin-right: 10px;
    &:focus {
        outline: none;
    }
`
const CustomFlex = styled(Flex)`
     @media screen and (max-width: 600px) {
        height: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
    }
`
const TextNFT = styled(Text)`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #75323C;
    text-shadow: 4px 4px 0px #E7CC9B;
    position: absolute;
    margin-top: 1rem;
    @media screen and (max-width: 600px) {
        height: 100%;
        top: -40vh;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        height: 100%;
        top: -27vh;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        height: 100%;
        top: -34vh;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        height: 100%;
        top: -34vh;
    }
`
const ButtonSwap = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border-radius: 6px;
    border: none;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    box-shadow: 5px 5px  #E7CC9B;
    cursor:${({ disabled }) => disabled ? "not-allowed" :"pointer"};
    background:${({ disabled }) => disabled ? "#ddd" :"#75323C"}; 
    position: absolute;
    margin-top: -100px;
    @media screen and (max-width: 600px) {
        margin-top: 50px;

    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        margin-top: -80px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
    }
`
