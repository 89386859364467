import React, {useState, useEffect} from 'react';
import abiFundgo from "components/abi/abiFundgo.json"
import axios from 'axios'
import { BASE_URL_GETNFT, API_KEY } from "config"
import { useWeb3React } from "@web3-react/core";

export const ListNFTFundgo = (contractAddress, chainId) => {
  const [ listBalace, setListNft ] = useState([])
  const { account } = useWeb3React()

  useEffect(() => {
      const fetchOnWallet = async () =>{
        try {
          const {data: response} = await axios({
              method: 'POST',
              url: `${BASE_URL_GETNFT}/${contractAddress}/function?chain=${chainId}&function_name=getUserIds`,
              headers: {
                  "X-API-Key": API_KEY,
              },
              data:{
                "params": {"user": account },
                "abi":abiFundgo
              }
          });
          setListNft(response)
        } catch (error) {
          console.error(error);
          setListNft([])
        }
      }
      if ( contractAddress && chainId ) {
          fetchOnWallet();
      }
    }, [contractAddress, chainId, account]);

  return { listBalace }
}

export const GetKeyMinterRole = (contractAddress, chainId) => {
  const [ isKeyMinRole, setisKeyMinRole ] = useState('')
  const { account } = useWeb3React()

  useEffect(() => {
      const fetchMinterRole = async () =>{
        try {
          const {data: response} = await axios({
              method: 'POST',
              url: `${BASE_URL_GETNFT}/${contractAddress}/function?chain=${chainId}&function_name=MINTER_ROLE`,
              headers: {
                  "X-API-Key": API_KEY,
              },
              data:{
                "params": {

                },
                "abi":abiFundgo
              }
          });
          setisKeyMinRole(response)
        } catch (error) {
          console.error(error);
        }
      }
      if ( contractAddress && chainId ) {
        fetchMinterRole();
      }
    }, [contractAddress, chainId, account]);

  return { isKeyMinRole }
}

  export const CheckMinterRole = (contractAddress, chainId, keyMinrole) => {
    const [ isMinRole, setisMinRole ] = useState(false)
    const { account } = useWeb3React()
  
    useEffect(() => {
        const checkMinterRole = async () =>{
          try {
            const {data: response} = await axios({
                method: 'POST',
                url: `${BASE_URL_GETNFT}/${contractAddress}/function?chain=${chainId}&function_name=hasRole`,
                headers: {
                    "X-API-Key": API_KEY,
                },
                data:{
                  "params": {
                    "role": keyMinrole,
                    "account": account
                  },
                  "abi":abiFundgo
                }
            });
            setisMinRole(response)
          } catch (error) {
            console.error(error);
          }
        }
        if ( contractAddress && chainId && account && keyMinrole) {
          checkMinterRole();
        }
      }, [contractAddress, chainId, keyMinrole, account]);
  
    return { isMinRole }
  }