import Blockies from "react-blockies";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";

/**
 * Shows a blockie image for the provided wallet address
 * @param {*} props
 * @returns <Blockies> JSX Elemenet
 */

function Blockie(props) {


  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.25 3.75H2.25C2.05109 3.75 1.86032 3.67098 1.71967 3.53033C1.57902 3.38968 1.5 3.19891 1.5 3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H15C15.1989 2.25 15.3897 2.17098 15.5303 2.03033C15.671 1.88968 15.75 1.69891 15.75 1.5C15.75 1.30109 15.671 1.11032 15.5303 0.96967C15.3897 0.829018 15.1989 0.75 15 0.75H2.25C1.65402 0.752468 1.08316 0.990313 0.661736 1.41174C0.240313 1.83316 0.00246785 2.40402 0 3V15C0.00246785 15.596 0.240313 16.1668 0.661736 16.5883C1.08316 17.0097 1.65402 17.2475 2.25 17.25H17.25C17.6478 17.25 18.0294 17.092 18.3107 16.8107C18.592 16.5294 18.75 16.1478 18.75 15.75V5.25C18.75 4.85218 18.592 4.47064 18.3107 4.18934C18.0294 3.90804 17.6478 3.75 17.25 3.75ZM13.875 11.625C13.6525 11.625 13.435 11.559 13.25 11.4354C13.065 11.3118 12.9208 11.1361 12.8356 10.9305C12.7505 10.725 12.7282 10.4988 12.7716 10.2805C12.815 10.0623 12.9222 9.86184 13.0795 9.70451C13.2368 9.54717 13.4373 9.44002 13.6555 9.39662C13.8738 9.35321 14.1 9.37549 14.3055 9.46064C14.5111 9.54578 14.6868 9.68998 14.8104 9.87498C14.934 10.06 15 10.2775 15 10.5C15 10.7984 14.8815 11.0845 14.6705 11.2955C14.4595 11.5065 14.1734 11.625 13.875 11.625Z" fill="#E7CC9B"/>
    </svg>
  );
}

export default Blockie;
