import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import {Text } from '@thaihuuluong/uikit';


const DropDownHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  transition: border-radius 0.15s;
  >svg{
    fill: #fff !important;
  }
`
const DropDownListContainer = styled.div`
  min-width: 90px;
  height: auto;
  position: absolute;
  overflow: hidden;
  z-index: 10;
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  box-sizing: border-box;
  /* box-shadow: inset 0px -2px 4px rgba(255, 255, 255, 0.16), inset 4px 3px 3px rgba(0, 0, 0, 0.28); */
  border-radius: 10px;
  background: #fff;

`
const DropDownContainer = styled.div`
  cursor: pointer;
  width: 99%;
  position: relative;
  height: 40px;
  min-width: 80px;
  box-sizing: border-box;
  border-radius: 10px;
  
  ${(props) =>
    props.isOpen &&
    css`
     
      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border-top-width: 0;
        border-radius: 0 0 5px 5px;
      }
    `}
    svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 100;
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  line-height: 29px;
`
const TextSelect = styled(Text)`
  font-size:18px;
  font-weight: 700;
  color: #6E6B67;
  
  @media only screen and (max-width: 768px) {
    font-size:14px;
  }
`
const Select = ({ options, onChange }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const toggling = () => {
      if(isOpen === true){
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    
  }

  const onOptionClicked = (selectedIndex) => () => {
    setSelectedOptionIndex(selectedIndex)
    setIsOpen(false)

    if (onChange) {
      onChange(options[selectedIndex])
    }
  }
  useEffect(() => {
    setContainerSize({width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })

    const handleClickOutside = () => {
    //   setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize}>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling}>
          <TextSelect color="text">{options[selectedOptionIndex].label}</TextSelect>
        </DropDownHeader>
      )}
      {isOpen ? <AiFillCaretDown onClick={toggling} width="10px"/> : <AiFillCaretUp onClick={toggling} width="10px"/> }
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) =>
            index !== selectedOptionIndex ? (
              <ListItem onClick={onOptionClicked(index)} key={option.label}>
                <TextSelect>{option.label}</TextSelect>
              </ListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default Select
