import React, {useState, useEffect, useCallback} from 'react';
import abiFundgo from "components/abi/abiFundgo.json"
import axios from 'axios'
import { BASE_URL_GETNFT, API_KEY } from "config"
import { useWeb3React } from "@web3-react/core";
import { useMoralis } from 'react-moralis';
import contract from "components/contract"


export const useApproved = (contractAddress) => {
    const { Moralis } = useMoralis();
    const [ penddingBuy, setPendingBuy ] = useState(false)
    const [requested, setRequested] = useState(false)

    const handleApproved = useCallback(async () => {
      setPendingBuy(true)
      try {
        const optionAdress =  {
          contractAddress: contractAddress,
          functionName: "setApprovalForAll",
          abi: abiFundgo,
          params: {
            operator: contractAddress,
            approved: 'true'
          }
        }
        const web3Provider = await Moralis.enableWeb3();

        const tx = await Moralis.executeFunction(optionAdress)
        const receipt = await tx.wait()
        console.log(receipt.status);
        if (receipt.status) {
          setRequested(true)
        } else {
          setRequested(false)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setPendingBuy(false)
        setRequested(true)
      }
    }, [contractAddress])
    return { handleApproved, requested, penddingBuy }
  }
  
  export const CheckApproved = (contractAddress, chainId, requested) => {
    const [ isApproved, setIsApproved ] = useState(false)
    const { account } = useWeb3React()
  
    useEffect(() => {
        const checkApprovedAll = async () =>{
          try {
            const {data: response} = await axios({
                method: 'POST',
                url: `${BASE_URL_GETNFT}/${contractAddress}/function?chain=${chainId}&function_name=isApprovedForAll`,
                headers: {
                    "X-API-Key": API_KEY,
                },
                data:{
                  "params": {
                    "account": account,
                    "operator": contractAddress
                  },
                  "abi":abiFundgo
                }
            });
            setIsApproved(response)
          } catch (error) {
            console.error(error);
          }
        }
        if ( contractAddress && chainId && account) {
            checkApprovedAll();
        }
      }, [contractAddress, chainId, requested]);
  
    return { isApproved }
  }

  export const Checkbalance = (contractAddress, chainId, idNft, requestedTransfer) => {
    const [ isBalance, setIsBalance ] = useState([])
    const { account } = useWeb3React()
  
    useEffect(() => {
        const checkBalanceOf = async () =>{
          try {
            const {data: response} = await axios({
                method: 'POST',
                url: `${BASE_URL_GETNFT}/${contractAddress}/function?chain=${chainId}&function_name=balanceOf`,
                headers: {
                    "X-API-Key": API_KEY,
                },
                data:{
                  "params": {
                    "account": account,
                    "id": idNft
                  },
                  "abi":abiFundgo
                }
            });
            setIsBalance(response)
          } catch (error) {
            console.error(error);
          }
        }
        if ( contractAddress && chainId && account) {
            checkBalanceOf();
        }
      }, [contractAddress, chainId, idNft, requestedTransfer, account]);
  
    return { isBalance }
  }

  export const useTransfer = (receviedAddress, idNft, amountNft, chainId) => {
    const { Moralis } = useMoralis();
    const [ penddingTransfer, setPenddingTransfer] = useState(false)
    const [requestedTransfer, setRequestedTransfer] = useState(false)
    const { account } = useWeb3React()

    const handleTransfer = useCallback(async () => {
        setPenddingTransfer(true)
      try {
        const optionAdress =  {
          contractAddress: contract.fundgoNFT[chainId],
          functionName: "transfer",
          abi: abiFundgo,
          params: {
            account:receviedAddress,
            id:idNft,
            amount:amountNft
          }
        }
        const web3Provider = await Moralis.enableWeb3();
        const tx = await Moralis.executeFunction(optionAdress)
  
        if (tx.status) {
            setRequestedTransfer(true)
        } else {
            setRequestedTransfer(false)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setPenddingTransfer(false)
       
      }
    }, [receviedAddress, idNft, amountNft, chainId])
    return { handleTransfer, requestedTransfer, penddingTransfer }
  }
  