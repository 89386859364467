import { Breadcrumbs, Button, Flex, Text } from '@thaihuuluong/uikit';
import { ListNFTFundgo } from 'hooks/fetchListNft';
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider';
import React from 'react';
import contract from "components/contract"
import styled from 'styled-components';
import Account from './Account';
import CardNft from './CardNft';
import Chains from './Chains';
import {DataNft } from './abi/configNft'
import { Link } from 'react-router-dom';
const ListNft = () => {

const { chainId } = useMoralisDapp();
const contractAddress = contract.fundgoNFT[chainId]
const { listBalace } = ListNFTFundgo(contractAddress, chainId)


    return (
        <Container>
            <Header>
                <FlexPass width='100%'>
                    <Breadcrumbs mb="32px">
                        <CustomButton variant='text' as={Link} to="//fundgo.network" style={{ fontWeight: 700, color: "#fff "}}>
                            ← Trở về Trang chủ
                        </CustomButton>
                    </Breadcrumbs>
                </FlexPass>
                <HeaderFlex>
                    <Chains/>
                    <Account/>
                </HeaderFlex>
            </Header>
            <CustomFlex>
                <CustomText className='title'>Danh sách chứng chỉ</CustomText>
                {/* <CustomText className='totle'>Trở thành thành viên để được hưởng nhiều quyền lợi hơn</CustomText> */}
                <CustomText className='totleList'>Bộ sưu tập NFT</CustomText>
            </CustomFlex>
            <FlexCard >
                    {listBalace.length === 0 ?
                        <Text>Không có dữ liệu</Text>
                    :
                        
                        <>
                            {listBalace.map((item) => {
                                return (
                                    <CardNft
                                        price = {item[0]}
                                        amount = {item[1]}
                                    />
                                )
                            })} 
                        </>
                    }
            </FlexCard>
        </Container>
    );
};

export default ListNft;

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding-top:0.25rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 1rem;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        background: none;
        padding: 2rem;

    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        background: none;
        padding: 3rem;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
        background: none;
        padding: 3rem;
    }
    @media screen and (min-width: 1441px) and (max-width: 2560px) {
        width: 100%;
        background: none;
        padding: 7rem;
    }
`

const Header = styled.div`
    display:flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    justify-content:space-between;
    width:100%;
    margin-top: -4rem;
    @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: center;
        margin-top: 0rem;
        flex-direction: column-reverse;

    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        justify-content: center;
        margin-top: 0rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: 0rem;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        margin-top: 0rem;
    }
`
const HeaderFlex = styled(Flex)`
    gap: 2px;
    @media screen and (max-width: 375px) {
        width: 100%;
        justify-content: space-around;
        gap: 0.25px;
    }
    @media screen and (min-width: 376px) and (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
        gap: 1px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        justify-content: flex-end;
        gap: 3px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        gap: 10px;

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        gap: 10px;
    }
`
const CustomText = styled(Text)`
    &.title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 73px;
        color: #75323C;
    }
    &.totle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 41px;
        color: #6E6B67;
    }
    &.totleList{
        margin-top: 1.25rem;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        color: #FDB533;
    }
    @media screen and (max-width: 600px){
    &.title{
        font-size: 20px;

    }
    &.totle{
        font-size: 18px;

    }
    &.totleList{
        font-size: 34px;
    }
    }
    @media screen and (min-width: 601px) and (max-width: 768px){
    &.title{
        font-size: 26px;

    }
    &.totle{
        font-size: 18px;

    }
    &.totleList{
        font-size: 34px;
    }
    }
`
const CustomFlex = styled.div`
    width: 100%;
    @media screen and (max-width: 600px){
        width: 100%;
        
    }
`
const FlexCard = styled(Flex)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (max-width: 600px){
        width: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        gap: 50px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {

    }
`
const CustomButton = styled(Button)`
  height:34px;
  font-size: 18px;
  background: #E7CC9B;
  border-radius: 6px;
  padding: 10px;
  @media screen and (max-width: 600px){
    width: 100%;
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    width: 100%;
  }
`
const FlexPass = styled(Flex)`
    @media screen and (max-width: 600px){
        margin-top: 1rem;
        justify-content: center;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        margin-top: 1.5rem;
    }
`