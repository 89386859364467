import React, { useCallback, useState } from "react";
import { useMoralis } from "react-moralis";
import abiFundgo from "components/abi/abiFundgo.json"
import contract from "components/contract"

export const useMintNft = (userAddress, idNft, amountNft, chainId) => {
    const { Moralis } = useMoralis();
    const [ penddingBuy, setPendingBuy ] = useState(false)
    const [requested, setRequested] = useState(false)

    const handleMint = useCallback(async () => {
      setPendingBuy(true)
      try {
        const optionAdress =  {
          contractAddress: contract.fundgoNFT[chainId],
          functionName: "mint",
          abi: abiFundgo,
          params: {
            account:userAddress,
            id:idNft,
            amount:amountNft
          }
        }
        const web3Provider = await Moralis.enableWeb3();

        const tx = await Moralis.executeFunction(optionAdress)

        if (tx.status) {
          setRequested(true)
        } else {
          setRequested(false)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setPendingBuy(false)
      }
    }, [userAddress, idNft, amountNft, chainId])
    return { handleMint, requested, penddingBuy }
  }
  