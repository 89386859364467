import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "./Blockie";
import { Button, Card, Modal } from "antd";
import { useState, useEffect } from "react";
import Address from "./Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from '@web3-react/injected-connector'



const HeaderConnect = styled.div`
  width: 100%;
  height: 50px;
  background: #75323C;
  display: flex;
  align-items: center;
  color: #fff;
  padding-left: 1rem;
`
const Container = styled.div`
  padding: 15px;
`

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "165px",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#75323C",
  },
};



function Account() {
  const { chainId } = useMoralisDapp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // danh sach chain id support
  const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 43114, 43113, 137, 80001],
  })
  const { active, account, library, connector, activate, deactivate } = useWeb3React()

  async function connect() {
    try {
      await activate(injected)
      localStorage.setItem('isWalletConnected', true)
    } catch (ex) {
      console.log(ex)
    }
  }

  async function disconnect() {
    try {
      deactivate()
      localStorage.setItem('isWalletConnected', false)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          await activate(injected)
          localStorage.setItem('isWalletConnected', true)
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    connectWalletOnPageLoad()
  }, [])
  
  if (!account) {
    return (
      <div
        style={styles.account}
        onClick={connect}
      >
        <p style={styles.text}>Kết nối ví</p>
      </div>
    );
  }

  return (
    <>
      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
        <p style={{ marginRight: "5px", ...styles.text }}>
          {getEllipsisTxt(account, 6)}
        </p>
        <Blockie currentWallet scale={3} />
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: "0px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="400px"
      >
        <HeaderConnect>
         Kết nối ví
        </HeaderConnect>
        <Container>
            <Card
              style={{
                marginTop: "10px",
                borderRadius: "1rem",
              }}
              bodyStyle={{ padding: "15px" }}
            >
              <Address
                avatar="left"
                size={6}
                copyable
                style={{ fontSize: "20px" }}
              />
              <div style={{ marginTop: "10px", padding: "0 10px" }}>
                <a
                  href={`${getExplorer(chainId)}/address/${account}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SelectOutlined style={{ marginRight: "5px" }} />
                  View on Explorer
                </a>
              </div>
            </Card>
            <Button
              size="large"
              type="primary"
              style={{
                width: "100%",
                marginTop: "10px",
                borderRadius: "0.5rem",
                fontSize: "16px",
                fontWeight: "500",
                background: '#75323C',
                borderColor: '#75323C',
              }}
              onClick={() => {
                disconnect();
                setIsModalVisible(false);
              }}
            >
              Disconnect Wallet
            </Button>
        </Container>
        
      </Modal>
    </>
  );
}

export default Account;
