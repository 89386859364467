import { Flex, Text, Button, Breadcrumbs } from '@thaihuuluong/uikit';
import React, { useState } from 'react';
import styled from 'styled-components';
import Account from "components/Account";
import Chains from "components/Chains";
import Select from 'components/Select';
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider';
import contract from "components/contract"
import NumberFormat from 'react-number-format';
import { useMintNft } from "../hooks/useMintNft"
import { CheckMinterRole, GetKeyMinterRole, ListNFTFundgo } from 'hooks/fetchListNft';
import { CheckApproved, Checkbalance, useApproved, useTransfer } from 'hooks/useTransfer';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';

function Transfer () {
     const { chainId, walletAddress } = useMoralisDapp();
     const [receviedAddress, setReceviedAddress] = useState('')
     const [ typeNft, setTypeNft ] = useState(10000)
     const { account } = useWeb3React()
     const [check, setCheck] = useState(0)
     const checkValidate = (address) => {
        if(!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
            setCheck(1)
          } else if(address === account) {
            setCheck(2)
          } else {
            setCheck(0)
          }
      }
     const handlePaste = () => {
         navigator.clipboard.readText().then(
           clipText => { 
             setReceviedAddress(clipText)
           });
        checkValidate(receviedAddress)

       }
    const handleSortOptionChange = (options) => {
        setTypeNft(options.value)
    }
    const handleInputChange = (e) => {
        const value = e.target.value
        setReceviedAddress(value)
        checkValidate(value)

      }

    const contractAddress = contract.fundgoNFT[chainId]
    const { handleApproved, requested, penddingBuy } = useApproved(contractAddress)
    const {isApproved} = CheckApproved(contractAddress, chainId, requested)


    const [ amount, setAmount ] = useState(0)
    const { handleTransfer, requestedTransfer, penddingTransfer } = useTransfer(receviedAddress, typeNft, amount, chainId );

    const {isBalance} = Checkbalance(contractAddress, chainId, typeNft, requestedTransfer)

    return (
        <Container>
            <Header>
                <FlexPass width='100%'>
                    <Breadcrumbs mb="32px">
                        <CustomButton variant='text' as={Link} to="//fundgo.network" style={{ fontWeight: 700, color: "#fff "}}>
                            ← Trở về Trang chủ
                        </CustomButton>
                    </Breadcrumbs>
                </FlexPass>
                <HeaderFlex>
                    <Chains/>
                    <Account/>
                </HeaderFlex>
            </Header>
            <CustomFlex width="100%">
                <TableNFT>
                    <TableImgNFT>
                        <ImgNFT src='/images/Fundgo/NFTFundGo.png'/>
                    </TableImgNFT>
                </TableNFT>
                <TableMint>
                    <TableMintNFT>
                        <Flex width='100%' justifyContent='center'>
                            <TextNFT>Chuyển NFTs</TextNFT>
                        </Flex>
                        <Flex width='100%' flexDirection='column' >
                            <TextTitle>- Chọn loại NFT -</TextTitle>
                            <TableInput>
                            <Select 
                                    options={[
                                    {
                                        label: 'NFT 10,000 VNĐ',
                                        value: 10000,
                                    },
                                    {
                                        label: 'NFT 100,000 VNĐ',
                                        value: 100000,
                                    },
                                    {
                                        label: 'NFT 1,000,000 VNĐ',
                                        value: 1000000,
                                    },
                                    {
                                        label: 'NFT 10,000,000 VNĐ',
                                        value: 10000000,
                                    },
                                    {
                                        label: 'NFT 100,000,000 VNĐ',
                                        value: 100000000,
                                    },
                                    {
                                        label: 'NFT 1,000,000,000 VNĐ',
                                        value: 1000000000,
                                    }
                                    ]}
                                    onChange={handleSortOptionChange}
                                />
                            </TableInput>
                
                        </Flex>
                        <Flex width='100%'>
                            <TextTitle>- NFT của bạn - {isBalance} </TextTitle>
                        </Flex>
                        <Flex width='100%' flexDirection='column' >
                            <TextTitle>- Nhập số lượng -</TextTitle>
                            <TableInput>
                                <CustomNumberFormat
                                        value={amount}
                                        thousandSeparator={!false}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            setAmount(parseFloat(value))
                                                
                                        }}
                                    />
                            </TableInput>
                        </Flex>
                        <Flex width='100%' flexDirection='column' marginTop='1rem'>
                            <TextTitle>- Địa chỉ ví nhận -</TextTitle>
                            <TableInput>
                                <InputAdress placeholder='Địa chỉ ví nhận' value={receviedAddress} onChange={handleInputChange}/>
                                <svg onClick={handlePaste} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4829 15.7154C19.5073 16.1208 19.9306 16.3943 20.2747 16.1784C22.4123 14.8373 23.8334 12.4596 23.8334 9.75002C23.8334 5.56186 20.4382 2.16669 16.2501 2.16669C13.5404 2.16669 11.1628 3.58781 9.82166 5.72537C9.60579 6.06944 9.87928 6.49277 10.2847 6.51715C15.1336 6.80865 19.1913 10.8707 19.4829 15.7154Z" fill="#E7CC9B"/>
                                    <path d="M17.3334 16.25C17.3334 20.4382 13.9382 23.8334 9.75008 23.8334C5.56192 23.8334 2.16675 20.4382 2.16675 16.25C2.16675 12.0619 5.56192 8.66669 9.75008 8.66669C13.9382 8.66669 17.3334 12.0619 17.3334 16.25Z" fill="#E7CC9B"/>
                                </svg>
                            </TableInput>
                        </Flex>
                        <Flex width='100%' justifyContent='center' marginTop='2rem'>
                            { isApproved === false ?
                                <ButtonMint
                                onClick={handleApproved}>
                                    Approved
                                </ButtonMint>
                            :
                                <ButtonMint

                                disabled={penddingTransfer || receviedAddress === "" || amount === 0 || check !== 0}
                                onClick={handleTransfer}>
                                    Chuyển
                                </ButtonMint>
                            }
                                
                           
                        </Flex>
                        { requestedTransfer === true &&
                            <Text marginTop='1rem' width='100%' textAlign='center'>Chuyển NFT thành công</Text>
                        }
                        {check === 2 &&
                            <Text marginTop='1rem' width='100%' textAlign='center'>Địa chỉ nhận đang là ví của bạn</Text>
                        }
                        {check === 1 &&
                            <Text marginTop='1rem' width='100%' textAlign='center'>Địa chỉ ví không đúng</Text>
                        }
                    </TableMintNFT>
                </TableMint>
            </CustomFlex>
          
        </Container>

    )
}
export default Transfer

const ButtonNFT = styled.button`
    width: 85px;
    height: 41px;
    background: #E7CC9B;
    border: 2px solid #75323C;
    border-radius: 12px;
`

const Header = styled.div`
    display:flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    justify-content:space-between;
    width:100%;
    padding-left: 3rem;
    padding-right: 3rem;
    @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: center;
        padding-left: 0rem;
        padding-right: 0rem;
        flex-direction: column-reverse;

    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
 

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {

    }
`
const HeaderFlex = styled(Flex)`
    gap: 20px;
    @media screen and (max-width: 375px) {
        width: 90%;
        justify-content: space-around;
        gap: 1px;

    }
    @media screen and (min-width: 376px) and (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
        gap: 3px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        justify-content: flex-end;
        gap: 5px;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        gap: 3px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        gap: 3px;
    }
`
const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top:2rem;
    /* background: url('/images/Fundgo/BG.jpg'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        flex-direction: column;
        background: none;
        padding: 10px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: auto;
        flex-direction: column;
        background: none;
        padding: 10px;
        
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
        height: 100%;
    }
`
const CustomFlex = styled(Flex)`
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        flex-direction: column-reverse;
        background: none;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: auto;
        flex-direction: column-reverse;
        background: none;
        padding: 10px;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
        height: 100%;
    }
`
const TableNFT = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
        display: block;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: 100%;
        display: block;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        margin-top: 2rem;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 100%;
        height: 100%;
    }
    
`
const TableMint = styled.div`
    width: 50%;
    height: 100%;
    z-index: 50;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 50%;
        height: 100%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 50%;
        height: 100%;
    }
`
const TableImgNFT = styled.div`
    width: 60%;
    height: 70%;
    margin-top: 0rem;
    margin-right: 1rem;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 80%;
        height: 70%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 80%;
        height: 70%;
    }
`
const ImgNFT = styled.img`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: 45%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        
    }
`
const TableMintNFT = styled.div`
    width: 60%;
    height: 80%;
    margin-top: 3rem;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
        margin-top: 2rem;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-top: 2rem;

    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 90%;
        height: 70%;
    }
`
const TextNFT = styled(Text)`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #75323C;
    text-shadow: 4px 4px 0px #E7CC9B;
`
const TextTitle = styled(Text)`
    margin-top: 1rem;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #75323C;
`
const TableInput = styled.div`
    width: 530px;
    height: 52px;
    align-items: center;
    border: 2px solid #6E6B67;
    border-radius: 6px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 400px;
        margin-right: 50px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 550px;
        margin-right: 50px;
    }
`
const TableInputPro = styled.div`
    width: 530px;
    height: 150px;
    align-items: center;
    border: 2px solid #6E6B67;
    border-radius: 6px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 400px;
        margin-right: 50px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 550px;
        margin-right: 50px;
    }
`
const InputAdress = styled.input`
    width: 93%;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #6E6B67;
    &:focus {
        outline: none;
    }
`
const ButtonMint = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border-radius: 6px;
    border: none;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    box-shadow: 5px 5px  #E7CC9B;
    cursor:${({ disabled }) => disabled ? "not-allowed" :"pointer"};
    background:${({ disabled }) => disabled ? "#ddd" :"#75323C"}; 
`
const CustomNumberFormat = styled(NumberFormat)`
    width: 93%;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #6E6B67;
    margin-left: 5px;
    &:focus {
        outline: none;
    }
`
const CustomButton = styled(Button)`
  height:34px;
  font-size: 18px;
  background: #E7CC9B;
  border-radius: 6px;
  padding: 10px;
  @media screen and (max-width: 600px){
    width: 100%;
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    width: 100%;
  }
`
const FlexPass = styled(Flex)`
    @media screen and (max-width: 600px){
        margin-top: 1rem;
        justify-content: center;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        margin-top: 1.5rem;
    }
`