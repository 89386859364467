import { Flex, Text } from '@thaihuuluong/uikit';
import { ListNFTFundgo } from 'hooks/fetchListNft';
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider';
import React, { useState } from 'react';
import styled from 'styled-components';


const CardNft = ({price, amount}) => {  
    const ImgNft = `/images/FundgoNft/${price}.png`
    return (
        <Container>
            <CustomFlex>
                    <TableNFT>
                        <ImgNFT src={ImgNft}/>
                    </TableNFT>
               <Flex marginTop='1.25rem'>
                <BorderLine/>
               </Flex>
                <Flex flexDirection='column' marginTop='0rem'>
                    <Flex width='100%' justifyContent='space-between' >
                        <CustomText color='#6E6B67' className='title'>Mệnh giá</CustomText>
                        <CustomText className='total' bold>{parseInt(price).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} VNĐ</CustomText>
                    </Flex>
                    <Flex width='100%' justifyContent='space-between' mt='1rem'>
                        <CustomText color='#6E6B67' className='title'>Số lượng</CustomText>
                        <CustomText className='total' bold>{parseInt(amount).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</CustomText>
                    </Flex>
                </Flex>
            </CustomFlex>
        </Container>
    );
};

export default CardNft;

const Container = styled.div`
    width: 400px;
    height: auto;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    background: #fff;

    @media screen and (max-width: 600px){
        width: 100%;
    }
    @media screen and (min-width: 601px) and (max-width: 768px){
        width: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){

    }
    @media screen and (min-width: 1025px) and (max-width: 1440px){
        background: #fff;

    }
`
const TableNFT = styled.div`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 600px){
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
    }
`
const ImgNFT = styled.img`
    width: 100%;
    height: 100%;
`
const CustomFlex = styled(Flex)`
    width: 350px;
    padding: 20px;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    flex-direction: column;
    padding-bottom: 2rem;
    @media screen and (max-width: 375px){
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
    }
    @media screen and (min-width: 376px) and (max-width: 600px){
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
    }
    @media screen and (min-width: 601px) and (max-width: 768px){
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
    }
    @media screen and (max-width: 1024px){
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
    }
`
const CustomText = styled(Text)`
    font-weight: bold;
@media screen and (max-width: 600px){
    &.title{
        font-size: 20px;

    }
    &.total{
        font-size: 18px;

    }
    
    }
    @media screen and (min-width: 601px) and (max-width: 768px){
    &.title{
        font-size: 26px;

    }
    &.total{
        font-size: 18px;
    }
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
    &.title{
        font-size: 26px;

    }
    &.total{
        font-size: 24px;
    }
    }
`
const BorderLine = styled.div`
    width: 100%;
    height: 1px;
    border: 1px solid #E5E5E5;
`